<template>
  <router-view></router-view>
</template>

<script>
import '../public/css/normalize.css';
import '../public/css/styles.scss';

export default {
  name: 'App',
}
</script>

<style>
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

</style>
